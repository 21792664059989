.catalogue-view {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  overflow-y: scroll;
  position: relative;

  &.animate {
    animation: apperAnimationRight 1.5s;

    @keyframes apperAnimationRight {
      from {
        opacity: 0;
        right: 100%;
      }
      to {
        opacity: 1;
        right: 0;
      }
    }
  }

  .catalogue-list {
    background: #f1f1f3;
    flex-grow: 1;

    .catalogue__filters {
      display: flex;
      margin-bottom: 2rem;

      & > * + * {
        margin-left: 2rem;
      }

      input {
        width: 400px;
        padding: 0.5rem;
      }
    }

    table.catalogue-table {
      width: 100%;
      background: #fff;
      border-collapse: collapse;
      font-size: 1rem;

      th,
      td {
        padding: 1rem;
      }

      thead {
        text-transform: uppercase;
        color: var(--blue);
        font-size: 0.9rem;
      }

      tbody {
        tr {
          border-top: 0.5px solid var(--blue);
        }
      }
    }
  }
}
