.bottom-bar-component {
  background: var(--blue);
  height: 4rem;
  color: #fff;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100vw;

  @media print {
    display: none;
  }
}

a {
  color: #fff;
}

.move-section-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition: 1.5s;

  &.active {
    left: 100%;
    transform: translateX(-100%);

    .back {
      opacity: 0;
      width: 0;
      padding: 0;
      visibility: hidden;
    }

    .forward {
      opacity: 1;
      padding: 0 1rem;
      width: max-content;
      visibility: visible;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .back {
    opacity: 1;
    padding: 0 1rem;
    transition: 1.5s;
    visibility: visible;
  }

  span {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .forward {
    opacity: 0;
    width: 0;
    padding: 0;
    transition: 1.5s;
    visibility: hidden;
  }

  .arrow-box {
    height: 100%;
    background: var(--pink);
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    cursor: pointer;

    &:hover {
      filter: contrast(50%);
    }

    svg {
      transition: 1.5s;
    }
  }
}

.move-section-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition: 1.5s;

  &.active {
    right: 100%;
    transform: translateX(100%);

    .tooltip-results {
      display: none;
    }

    .forward {
      opacity: 0;
      width: 0;
      padding: 0;
    }

    .back {
      opacity: 1;
      padding: 0 1rem;
      width: max-content;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .tooltip-results {
    color: #fff;
    font-weight: 500;
    padding: 2rem;
    background: var(--pink);
    position: absolute;
    top: -6rem;
    text-transform: uppercase;
    width: max-content;
    right: 1rem;
    animation: bounce 1s infinite;

    &::before {
      background: var(--pink);
      transform: rotate(45deg);
      content: "";
      position: absolute;
      top: calc(100% - 0.5rem);
      right: 1rem;
      width: 1rem;
      height: 1rem;
    }

    @keyframes bounce {
      0%,
      100% {
        transform: translateY(-10%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      }
      50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  .forward {
    opacity: 1;
    padding: 0 1rem;
    transition: 1.5s;
  }

  span {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .back {
    opacity: 0;
    width: 0;
    padding: 0;
    transition: 1.5s;
  }

  .arrow-box {
    height: 100%;
    background: var(--pink);
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    cursor: pointer;

    &:hover {
      filter: contrast(50%);
    }

    svg {
      transition: 1.5s;
    }
  }
}
