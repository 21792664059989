.detail-product-component {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;

  .pop-up-product {
    background: #fff;
    margin: 3rem auto;
    width: 65%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    position: relative;

    .close-popup {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
    .download-icon {
      color: var(--blue);
      &:hover {
        color: var(--pink);
      }
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      margin: 0 24%;
      margin-top: 32px;
      // & > * {
      //   flex: 1;
      //   display: flex;
      // }
    }
    @media print {
      width: 100%;
      margin: 0;
      orientation: portrait;
      height: 100vh;
    }

    & > * + * {
      margin-top: 2rem;
    }
    .pop-up__body > * + * {
      margin-top: 2rem;
    }

    .pop-up__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 1rem;
        color: var(--blue);
      }

      img {
        height: 4rem;
      }
    }
    .table-title {
      color: var(--blue);
      text-transform: uppercase;
      margin-bottom: 0rem;
    }
    table.detail-product-table {
      margin-top: 1rem;
      width: 100%;
      // margin-bottom: 2rem;
      // margin-top: 2rem;
      thead {
        background-color: var(--blue);
        color: #fff;
        border: 1px solid var(--blue);
      }
      tbody {
        border: 1px solid #ccc;
        tr:nth-child(even) {
          background: #deeaf6;
          &.secondary {
            background: #fadaec;
          }
        }
        td,
        th {
          border-right: 1px solid #ccc;
          border-left: 1px solid #ccc;
        }
      }

      tr {
        height: 2rem;
      }
      th {
        text-transform: capitalize;
      }

      td,
      th {
        // padding: 0 2rem;
        vertical-align: middle;
        padding: 8px 8px;
      }

      tr:first-child,
      th {
        border-top: 0;
      }

      tr td:first-child,
      th:first-child {
        border-left: 0;
      }

      tr:last-child td,
      th {
        border-bottom: 0;
      }

      tr td:last-child,
      th:last-child {
        border-right: 0;
      }
    }
  }
}
