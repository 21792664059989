.results-view__specs {
  margin-bottom: 3rem;

  .specs__title {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    height: 4rem;
  }

  table.specs-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: var(--blue);
      border: 1px var(--blue) solid;
      &.secondary {
        background-color: var(--pink);
        border: 1px var(--pink) solid;
      }
      th {
        padding: 8px 0;
        font-weight: 500;
        color: #fff;
        &.value {
          text-transform: capitalize;
        }
      }
    }

    tbody {
      border: 1px #ccc solid;
      tr:nth-child(even) {
        background: #deeaf6;
        &.secondary {
          background: #fadaec;
        }
      }

      th {
        width: 30%;
        padding: 8px 0;
        // text-align: left;6
        text-transform: uppercase;
        font-weight: 500;
        border-right: 1px #ccc solid;
      }

      .value {
        width: calc(70% / 4);
        text-align: center;
        font-size: 0.9rem;
        border-right: 1px #ccc solid;

        &:last-child {
          width: 100%;
        }
      }
      tr.secondary {
        width: calc(70% / 5);
        .value {
          &:nth-child(2) {
            width: calc(70% / 2);
          }
        }
      }
      // tr:last-child {
      //   th {
      //     padding-bottom: 24px;
      //   }
      // }
    }
  }
}
