.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1550px;
  padding: 0 4%;
}

.section {
  padding-top: 3rem;
  padding-bottom: 4rem;

  @media print {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}
