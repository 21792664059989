.configuration__select-config {
  margin-bottom: 1rem;
  text-transform: uppercase;
  cursor: default;
  color: var(--blue);
  font-size: 1rem;
  font-weight: 500;
  border: 0;
  position: relative;

  &.active {
    .placeholder {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .select-line {
    display: flex;
    align-items: center;

    .placeholder {
      color: var(--blue);
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid var(--blue);
      padding: 0.3rem 0;

      svg {
        flex-shrink: 0;
        cursor: pointer;
      }
    }

    .tooltip-info {
      position: relative;

      svg {
        color: var(--greyDark);
        border-radius: 50%;

        &:hover {
          color: var(--blue);
        }
      }

      .tooltip-description {
        position: absolute;
        width: max-content;
        z-index: 1;
        left: 0;
        top: 0;
        background: var(--black);
        padding: 0.5rem;
        color: #fff;
        transform: translate(-75%, -100%);
      }
    }
  }

  .options-container {
    position: absolute;
    z-index: 1;
    /* padding: 1rem 0; */
    top: 100%;
    left: 0;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    background: #fff;
    box-shadow: 0 2px 20px var(--greyDark);
    max-height: 10.5rem;
    min-height: 6.5rem;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .option {
      padding: 0 1rem;
      height: 3rem;
      line-height: 3rem;

      &:hover {
        color: #fff;
        background: var(--blue);
      }
    }
  }
}
