.configurator__steps-config {
  display: flex;
  justify-content: center;
  align-items: center;

  .steps {
    margin-left: 4rem;
    display: flex;

    & > * + * {
      margin-left: calc(1.5rem - 2px);
    }

    .step {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        .step__dot::after {
          position: absolute;
          content: "";
          top: 50%;
          right: 100%;
          background: var(--pink);
          transform: translateY(-50%);
          width: 1.5rem;
          height: 2px;
        }
      }

      .step__dot {
        position: relative;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        border: 2px solid var(--pink);

        &.active {
          background: var(--pink);

          &.hover:hover {
            cursor: pointer;
            box-shadow: 0 0 5px #000;
          }
        }

        .tooltip-step {
          position: absolute;
          top: -2rem;
          left: 50%;
          transform: translateX(-50%);
          width: max-content;
        }
      }

      .step__text {
        display: none;
        margin: 0 1rem;
        color: var(--blue);
        font-weight: 500;
        font-size: 1.5rem;

        &.active {
          display: block;
        }
      }
    }
  }
}
