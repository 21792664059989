.navbar__tooltip-code {
  position: relative;

  .tooltip-code {
    color: var(--greyDark);
    cursor: pointer;

    &:hover {
      color: var(--black);
    }

    &.active {
      color: var(--black);
    }
  }

  .tooltip-box {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 50%;
    transform: translateX(-50%);
    background: var(--grey);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    &::after {
      content: "";
      position: absolute;
      top: -0.5rem;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 1rem;
      aspect-ratio: 1;
      background: var(--grey);
    }

    .active-code {
      align-self: flex-start;
      width: 200px;
      word-wrap: break-word;
      position: relative;
      margin-bottom: 1rem;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -2.5rem;
        color: var(--blue);
        cursor: pointer;
      }

      .feedback-clipboard {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -11rem;
        padding: 1rem;
        background: #000;
        color: #fff;
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;

        &.active {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .input-code {
      display: flex;
      align-items: center;
      position: relative;

      input {
        width: 200px;
        padding: 0.5rem;
      }

      svg {
        margin-left: 1rem;
        color: green;
        cursor: pointer;
      }

      .feedback-error {
        position: absolute;
        top: 3rem;
        padding: 1rem;
        background: red;
        left: 50%;
        color: #fff;
        transform: translateX(-50%);
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;

        &.active {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
