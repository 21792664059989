.configurator__panel {
  width: 100%;
  height: 100%;
  background: var(--grey);
  padding: 1.5rem;
  position: relative;
  // margin-right: 1rem;
  margin-left: 1rem;

  .title {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1rem;

    width: 100%;

    text-align: center;

    svg {
      flex-shrink: 0;
      margin-left: 1rem;
      width: 2rem;
      cursor: pointer;

      &:hover {
        color: var(--blue);
      }
    }
  }

  .configuration {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .configuration-numbers {
    display: flex;
    margin-bottom: 1.5rem;
    justify-content: center;
    .configuration-number {
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
      border-radius: 50%;
      margin: 0 0.2rem;
      font-weight: 600;
      color: #fff;
      background: var(--blue);
    }
  }

  .configurator-pane__form {
    display: flex;
    flex-direction: column;

    .btn {
      margin: 2rem 0 1rem;
    }
  }
}
