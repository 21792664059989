.results-view__results-list {
  margin-bottom: 4rem;
  font-weight: 500;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    page-break-before: always;
    float: none !important;
  }

  .results-list__title {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .results-list {
    .result {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      .result__left {
        display: flex;
        align-items: center;

        & > * + * {
          margin-left: 2rem;
        }
      }

      .cta {
        margin-left: 0.5rem;
        display: flex;

        & > * + * {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .results-list-couple {
    // background: #fff;
    display: flex;
    flex-direction: column;

    .results-couple {
      // padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      tr {
        display: flex;
      }
      th,
      td {
        flex: 1;
      }
      // .result {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   width: 46%;

      // .result__left {
      //   display: flex;
      //   align-items: center;

      //   & > * + * {
      //     margin-left: 2rem;
      //   }
      // }

      // .cta {
      //   margin-left: 0.5rem;
      //   display: flex;

      //   & > * + * {
      //     margin-left: 0.5rem;
      //   }
      // }
      // }
    }
  }
}

table.result-product-table {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  thead {
    background-color: var(--blue);
    color: #fff;
    font-weight: 500;
    // border: 1px solid var(--blue);
  }
  tbody {
    background: #fff;
    // border: 1px solid #ccc;
    tr:nth-child(even) {
      background: #deeaf6;
      &.secondary {
        background: #fadaec;
      }
    }
    td,
    th {
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
  }

  th {
    text-transform: capitalize;
  }

  td,
  th {
    font-weight: 500;
    // padding: 0 2rem;
    padding: 8px 8px;
    vertical-align: middle;
  }

  tr:first-child,
  th {
    border-top: 0;
  }
  tr:last-child td {
    border-bottom: 1px #ccc solid;
  }
}
