.catalogue-filters__select {
  text-transform: uppercase;
  cursor: default;
  color: var(--blue);
  font-size: 1rem;
  font-weight: 500;
  border: 0;
  position: relative;

  .value-select {
    color: var(--blue);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--blue);
    padding: 0.3rem 0;
    width: 200px;

    svg {
      cursor: pointer;
    }
  }

  .options-container {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    background: #fff;
    box-shadow: 0 2px 20px var(--greyDark);
    max-height: 10.5rem;
    min-height: 6.5rem;
    overflow-y: scroll;
    scrollbar-width: none;
    width: max-content;
    min-width: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }

    .option {
      padding: 0 1rem;
      height: 3rem;
      line-height: 3rem;

      &:hover {
        color: #fff;
        background: var(--blue);
      }
    }
  }
}
