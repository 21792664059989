.navbar__select-area-box {
  margin-top: 3.5rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: 1.5rem;
  }
}
