@font-face {
  font-family: "IBM Plex Sans";
  src: url("IBMPlexSans-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("IBMPlexSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("IBMPlexSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
