.navbar {
  flex-shrink: 0;
  padding: 0 2%;
  margin: 0 auto;
  width: 100%;
  max-width: 1550px;
  height: 8rem;
  display: flex;
  align-items: center;

  a {
    height: 50%;
    position: relative;
    z-index: 2;

    img {
      height: 100%;
    }
  }

  .navbar__content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-weight: 500;
    font-size: 3rem;
    margin-left: 3rem;
    user-select: none;
  }
}
