@import "./reset.css";
@import "./font/font.css";
@import "./variables";
@import "./layout";
@import "./typography";
@import "./buttons";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scrollbar-width: thin;
  scrollbar-color: #005984 #cccccc;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #cccccc;
  }

  &::-webkit-scrollbar-thumb {
    background: #005984;
  }
}

#root {
  overflow: hidden;
}

body {
  font-family: var(--fontFamily);
  color: var(--black);
  -webkit-print-color-adjust: exact !important;

  &.no-scroll {
    overflow: hidden;
  }
}

@page {
  margin: 0;
  size: landscape;
}

.result-title {
  text-transform: uppercase;
  color: var(--blue);
  line-height: 1.2rem;
  height: 2.6rem;
  font-weight: 500;
  cursor: pointer;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  position: relative;
  margin: 1rem 0;

  &::before {
    content: "";
    width: 3rem;
    height: 1.2rem;
    position: absolute;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent, white);
  }

  &:hover {
    color: var(--blueHover);
  }

  &.no-mar {
    margin: 0;
    height: unset;

    &::before {
      display: none;
    }
  }

  &.no-event {
    pointer-events: none;
    cursor: default;
  }
}

.result-img {
  //  height: 13rem;
  width: 50%;
  align-self: center;
  margin: 0 1rem 1rem;
  background: var(--grey);
  position: relative;

  &::after {
    position: absolute;
    content: "placeholder";
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }

  @media print {
    display: none;
  }

  // &.no-event {
  //   // height: 26rem;
  // }
}

.heading-general {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid darkgrey;
  height: 4rem;
}

.btn-tooltip {
  color: #000;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 1rem;
  background: var(--blueLight);
  position: absolute;
  top: -4rem;
  text-transform: uppercase;
  width: max-content;
  right: 1rem;
  animation: bounce 1s infinite;

  &::before {
    background: var(--blueLight);
    transform: rotate(45deg);
    content: "";
    position: absolute;
    top: calc(100% - 0.5rem);
    right: 1rem;
    width: 1rem;
    height: 1rem;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-10%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
.btn-tooltip-reverse {
  color: #000;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 1rem;
  background: var(--blueLight);
  position: absolute;
  bottom: -4rem;
  text-transform: uppercase;
  width: max-content;
  right: 1rem;
  animation: bounce 1s infinite;

  &::before {
    background: var(--blueLight);
    transform: rotate(45deg);
    content: "";
    position: absolute;
    bottom: calc(100% - 0.5rem);
    right: 1rem;
    width: 1rem;
    height: 1rem;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(10%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
.close-popup {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
