.test-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: var(--blue);

  font-weight: 500;
}

.progress-bar {
  background: var(--pink);
  height: 24px;
  margin-top: -1px;
  border: 1px solid var(--pink);
}

.progress-bar-container {
  height: 24;
  width: 100%;
  border: 1px solid #ccc;
  margin: 1rem 0;
}
.success-results {
  color: var(--blue);
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0.8rem 0;
}
.failed-results {
  color: crimson;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.errors-table {
  margin-top: 2px;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
  &-header {
    display: flex;
    justify-content: space-between;
    //     background-color: var(--blue);
    background-color: crimson;
    color: white;
    //     font-size: 1.2rem;
    font-weight: 500;
    padding: 10px;
    //     border-bottom: 1px solid var(--blue);
    border-bottom: 1px solid crimson;
    & > div {
      flex: 1;
      text-align: center;
    }
  }
  &-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid var(--blue);
    & > div {
      flex: 1;
      text-align: center;
    }
  }
}
