.form-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: #fff;
  width: 100vw;
  overflow: auto;
  // padding-bottom: 2rem;

  .title {
    font-weight: 500;
    font-size: 2rem;
    margin-left: 3rem;
    user-select: none;
    padding-bottom: 2rem;
  }

  .form-container {
    height: calc(100vh - 8rem);

    .form__wrapper {
      margin: 0 auto;
      width: 60%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;

      .extended {
        grid-column: span 2;
      }

      & > input {
        height: 1.5rem;
        width: 100%;
        border-bottom: 2px solid var(--blue);
        padding: 1rem 0;
      }

      .textarea-box {
        label {
          color: var(--blueLight);
          font-weight: 500;
          opacity: 1;
          text-transform: uppercase;
        }

        textarea {
          margin-top: 1rem;
          padding: 0.5rem;
          font-size: 1.2rem;
          width: 100%;
          border: 2px solid var(--blue);
        }
      }

      .checkbox-box {
        display: flex;
        flex-direction: column;

        & > * + * {
          margin-top: 1rem;
        }

        .checkbox {
          display: flex;
          align-items: center;
        }

        input {
          cursor: pointer;
          width: 2rem;
          height: 2rem;
          border: 2px solid var(--blue);
          position: relative;

          &:checked {
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 70%;
              height: 70%;
              background: var(--blue);
            }
          }
        }

        label {
          color: var(--blue);
          width: 100%;
          cursor: pointer;
          margin-left: 1.5rem;
        }
      }

      .submit-box {
        display: block;
        margin: 0 auto;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        &:hover {
          span {
            color: var(--pink);
          }

          .line::after {
            background: var(--pink);
            right: 100%;
            transform: translateX(100%);
          }
        }

        span {
          color: var(--blue);
          transition: 0.4s;
        }

        .line {
          width: 4rem;
          height: 2px;
          background: var(--blue);
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            transition: 0.4s;
          }
        }

        span {
          margin-left: 1rem;
          font-weight: 500;
          letter-spacing: 0.16em;
        }
      }
    }
  }

  .form__close {
    display: flex;
    align-items: center;
    color: var(--blue);
    cursor: pointer;

    &:hover {
      color: var(--pink);
    }

    span {
      text-transform: uppercase;
    }

    svg {
      font-size: 2rem;
      margin-left: 0.5rem;
    }
  }

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: 6px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid var(--blue);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
