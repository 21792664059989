.configurator-view {
  display: flex;
  align-items: center;
  height: calc(100vh - 12rem);
  animation: apperAnimation 1.5s;

  @keyframes apperAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .wrapper-preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .configurator__side-panel {
      width: 18%;

      &.lines-panel {
        // align-self: flex-end;
        // background-color: red;
        // height: calc(100vh - 12rem);
        display: flex;
        align-items: center;
        margin-top: 4rem;
      }
    }

    .configurator__preview {
      position: relative;
      border: 3px solid var(--blue);
      display: grid;
      grid-template-rows: repeat(2, 6rem);
      grid-template-columns: repeat(5, 6rem);
      justify-content: center;
      grid-auto-flow: column;
      gap: 4rem;
      padding: 4rem;

      .preview__circle {
        border-radius: 50%;
        border: 3px solid var(--blue);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 0 10px #fff;
        pointer-events: none;

        &.hydraulic {
          border-color: var(--blue);
          background: var(--blue);
          cursor: pointer;
          pointer-events: initial;

          &.active {
            box-shadow: 0 0 0 7px #fff, 0 0 0 12px var(--blueLight);
            pointer-events: initial;
          }
        }

        &.electric {
          border-color: var(--pink);
          background: var(--pink);
          cursor: pointer;
          // pointer-events: initial;

          &.active {
            box-shadow: 0 0 0 7px #fff, 0 0 0 12px var(--pinkLight);
            // pointer-events: initial;
          }
        }

        .delete {
          position: absolute;
          top: -1.2rem;
          right: -1.2rem;

          &:hover {
            color: red;
          }
        }

        .number {
          position: absolute;
          top: -1rem;
          left: -1rem;
        }

        .check {
          color: #fff;
        }
      }
    }
  }
}
