.select-area-box__select {
  width: 240px;
  background: var(--blue);
  cursor: default;
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;

  &.electric {
    background: var(--pink);

    .options-container {
      background: var(--pink);
      scrollbar-color: #fff var(--pink);

      &::-webkit-scrollbar-track {
        background: var(--pinkHover);
      }

      .option {
        &:hover {
          background: var(--pinkHover);
        }
      }
    }
  }

  .placeholder {
    padding: 0.8rem 1.2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }

  .options-container {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    background: var(--blue);
    max-height: 9rem;
    height: auto;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #fff var(--blueHover);
    &::-webkit-scrollbar {
      width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      background: var(--blueHover);
    }
    &::-webkit-scrollbar-thumb {
      background: #fff;
    }

    .option {
      text-align: center;
      padding: 0.5rem 0;
      /* cursor: pointer; */
      &:hover {
        background-color: var(--blueHover);
      }
    }
  }
}
