a {
  text-decoration: none;
}

input,
textarea {
  color: var(--blue);
  font-family: var(--fontFamily);
  outline: none !important;
}

input {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  appearance: none;
  border: none;

  &::placeholder {
    opacity: 1;
    color: var(--blueLight);
  }
}

textarea {
  font-size: 1.5rem;
  height: 12rem;
  resize: none;
}
