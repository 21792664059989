.results-view {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  overflow-y: scroll;
  position: relative;

  @media print {
    height: unset;
  }

  &.animate {
    animation: apperAnimationLeft 1.5s;

    @keyframes apperAnimationLeft {
      from {
        opacity: 0;
        left: 100%;
      }
      to {
        opacity: 1;
        left: 0;
      }
    }
  }

  .results-view__intro {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      page-break-after: always;
      float: none !important;
    }

    @media print {
      flex-wrap: wrap;
      /* height: 100vh; */
    }

    .intro__single-result-spec {
      display: flex;
      flex-direction: column;
      width: 55%;

      @media print {
        width: 100%;
      }
    }

    .popup-disclaimer {
      align-self: flex-start;

      .disclaimer-notes {
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 1rem;

        &:hover {
          text-decoration: underline;
        }
      }

      .popup-content {
        line-height: 1.2;
      }
    }
  }

  .results-view__list {
    background: var(--grey);

    @media print {
      padding: 0;
      background: var(--grey);
    }

    .list__title {
      font-weight: 500;
      font-size: 2rem;
      margin-bottom: 3rem;

      @media print {
        margin-bottom: 0;
      }
    }
  }
}
