.configuration__select-repeat {
  text-transform: uppercase;
  cursor: default;
  color: var(--blue);
  font-size: 1rem;
  font-weight: 500;
  position: relative;

  .placeholder {
    position: relative;

    .normal {
      border: 2px solid var(--blue);
      padding: 0.7rem 0.5rem;

      &:hover {
        color: #fff;
        background: var(--blue);
      }
    }

    .filled {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      color: #fff;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--blue);
    }
  }

  .options-container {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    font-size: 1rem;
    background: #fff;
    font-weight: 500;
    max-height: 10.5rem;
    min-height: 6.5rem;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .option {
      padding: 0 1rem;
      height: 3rem;
      line-height: 3rem;

      &:hover {
        color: #fff;
        background: var(--blue);
      }
    }
  }
}
