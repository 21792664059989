:root {
  font-size: 14px;

  --fontFamily: "IBM Plex Sans", Helvetica, Arial, sans-serif;

  --black: #383e42;
  --blue: #005984;
  --blueHover: #1a6a90;
  --blueLight: #99bdce;
  --pink: #e6007e;
  --pinkHover: #e91a8b;
  --pinkLight: #f599cb;
  --grey: #f1f1f3;
  --greyDark: #c9c9c9;
}
