.btn {
  font-size: 0.8rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border: 2px solid var(--blue);
  text-transform: uppercase;
  padding: 0.8rem 1.2rem;
  font-weight: 400;
  letter-spacing: 1px;
  background: var(--blue);

  &:hover {
    background: var(--pink);
    border-color: var(--pink);
  }

  &.simple {
    cursor: unset;
    pointer-events: none;
  }

  &.left {
    align-self: flex-start;
  }

  &.center {
    align-self: center;
  }

  &.right {
    align-self: flex-end;
  }

  &.no-print {
    @media print {
      display: none;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.modified {
    background: var(--pink);
  }

  &.secondary {
    background: var(--pink);

    &:hover {
      background: var(--blue);
    }
  }

  &.outline {
    background: unset;
    border: 2px solid var(--blue);
    color: var(--blue);
    position: relative;

    &.pad {
      padding: 0;
    }

    &:hover {
      color: #fff;
      background: var(--blue);
      border-color: var(--blue);
    }

    &.danger {
      // border: 2px solid orange;
      // color: orange;
      border: 2px solid var(--pink);
      color: var(--pink);

      &:hover {
        color: #fff;
        // background: orange;
        // border-color: orange;
        background: var(--pink);
        border-color: var(--pink);
      }
    }
  }

  &.table {
    padding: 2px 1.2rem;
  }
}
